<template>
	<div id="monthPassengerFlow">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="routeId" label="线路:">
				<!-- <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
				<el-select v-model="form.routeId" placeholder="请选择线路">
					<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="dateType" label="日期类型:">
				<el-select v-model="form.dateType" placeholder="请选择">
					<el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="查询日期:" prop="searchByYearMonth">
				<el-date-picker v-model="form.searchByYearMonth" value-format="yyyy-MM" type="month" placeholder="选择日期">
				</el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
				<!-- <el-row> -->
				<!-- <el-button type="primary">导出</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<el-row>
			<el-col :span="6" class="select_box">
				<el-radio v-model="radio" label="1">上行</el-radio>
				<el-radio v-model="radio" label="2">下行</el-radio>
			</el-col>
		</el-row>
		<echart :list="elist"></echart>
		<el-row>
			<el-col :span="12">
				<el-card>
					<div slot="header">
						<span>上行站点</span>
					</div>
					<div>
						<jy-table max-height="595" :data="upList">
							<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
							<jy-table-column prop="loginUser" label="线路"></jy-table-column>
							<jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
							<jy-table-column prop="loginName" label="站点名称"></jy-table-column>
							<jy-table-column prop="loginIp" label="上车人数汇总"></jy-table-column>
							<jy-table-column prop="logoutTime" label="下车人数汇总"></jy-table-column>
						</jy-table>
						<div class="onCarBox">
							上车人数总计：{{ upGetOnSumNum }}
							<div class="secondBox">下车人数总计：{{ upGetOffSumNum }}</div>
						</div>
						<!-- <div class="jy_pagination">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                                </el-pagination>
                            </div> -->
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card>
					<div slot="header">
						<span>下行站点</span>
					</div>
					<div>
						<jy-table max-height="595" :data="downList">
							<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
							<jy-table-column prop="loginUser" label="线路"></jy-table-column>
							<jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
							<jy-table-column prop="loginName" label="站点名称"></jy-table-column>
							<jy-table-column prop="loginIp" label="上车人数汇总"></jy-table-column>
							<jy-table-column prop="logoutTime" label="下车人数汇总"></jy-table-column>
						</jy-table>
						<div class="onCarBox">
							上车人数总计：{{ downGetOnSumNum }}
							<div class="secondBox">下车人数总计：{{ downGetOffSumNum }}</div>
						</div>
						<!-- <div class="jy_pagination">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                                </el-pagination>
                            </div> -->
					</div>
				</el-card>
			</el-col>
		</el-row>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/waybillOperate/getTree"
			title="选择线路组织"
			:defaultProps="props"
		></institutions-tree>
	</div>
</template>
<script>
import echart from "@/components/pages/admin/statisticalAnalysis/passengerFlow/monthPassengerFlow/charts";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			props: {
				children: "children",
				label: "text"
			},
			// echarts列表
			elist: [],
			radio: "1",

			upGetOnSumNum: "0",
			upGetOffSumNum: "0",
			downGetOnSumNum: "0",
			downGetOffSumNum: "0",

			form: {
				routeName: "",
				routeId: "",
				searchByYearMonth: "",
				dateType: ""
			},
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			upList: [
				{
					routeName: "",
					siteName: "",
					getOnTotal: "",
					getOffTotal: ""
				}
			],
			downList: [
				{
					routeName: "",
					siteName: "",
					getOnTotal: "",
					getOffTotal: ""
				}
			],
			typeoptions: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "1",
					label: "工作日"
				},
				{
					value: "2",
					label: "周末"
				}
			],
			routeOptions: []
		};
	},
	created() {
		this.getList();
		this.getDate();
	},
	activated() {
		this.getRouteList();
	},
	components: {
		echart,
		institutionsTree
	},
	watch: {
		radio: {
			handler(val) {
				if (val == "1") {
					this.elist = this.upList;
				} else {
					this.elist = this.downList;
				}
			},
			immediate: true
		}
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		getDate() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth();
			if (month == 0) {
				month = 12;
			}
			if (month < 10) {
				month = "0" + month;
			}
			var nowDate = year + "-" + month;
			this.form.searchByYearMonth = nowDate;
		},
		getList() {
			let option = {
				...this.form
				// pageIndex: this.pageIndex,
				// pageSize: this.pageSize
			};
			let url = "/statSiteRoutePfMonthly/queryPageList";
			this.$http.post(url, option).then(res => {
				if (res.retain == "1") {
					this.upList = [];
					this.downList = [];
					this.$message({
						message: res.error,
						type: "warning"
					});
				} else {
					this.upList = res.detail.upList;
					this.downList = res.detail.downList;
					this.upGetOnSumNum = res.detail.upGetOnSumNum;
					this.upGetOffSumNum = res.detail.upGetOffSumNum;
					this.downGetOnSumNum = res.detail.downGetOnSumNum;
					this.downGetOffSumNum = res.detail.downGetOffSumNum;
					this.radio = "1";
					this.elist = this.upList;
					// this.total = res.detail.total
				}
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.routeId = "";
			this.oncheck();
		},
		// 线路组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		addInstitutions(data) {
			console.log(data);
			this.form.routeId = data.id;
			this.form.routeName = data.text;
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped="monthPassengerFlow">
#monthPassengerFlow {
	.select_box {
		margin-left: 50px;
	}

	.onCarBox {
		padding-top: 10px;
		font-size: 12px;

		.secondBox {
			display: inline-block;
			margin-left: 20px;
		}
	}
}
</style>
